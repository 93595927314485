import React, { useState } from 'react';
import { Form, Button, Container, Card } from 'react-bootstrap';
import CustomModal from './Modal';
import LoadingSpinner from './Spinner';

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, message } = event.target;
    if (!name.value.trim() || !email.value.trim() || !message.value.trim()) {
      setModalTitle('Error');
      setModalMessage('Please fill in all fields!');
      setShowModal(true);
    } else {
      
      const formData = new FormData(event.target);
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          senderName: formData.get('name'),
          senderEmail: formData.get('email'),
          message: formData.get('message'),
        }),
        
      };

      try {
        setIsLoading(true);
        const response = await fetch(
          'https://47piw2a1r0.execute-api.us-east-1.amazonaws.com/default/sendEmail',
          requestOptions
        );
        
        if (!response.ok) {
          throw new Error('Error in fetch');
        }
        
        setTimeout(() => {
          setIsLoading(false);
          setModalTitle('Success');
          setModalMessage('Email sent successfully!');
          setShowModal(true);
        }, 3000);
       
        
      } catch (error) {
        setTimeout(() => {
          setIsLoading(false);
          setModalTitle('Error');
          setModalMessage('An unknown error occurred.');
          setShowModal(true);
        }, 1000);
       
      }
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container className="contactDiv jumbotron">
      <Card style={{ width: '50rem', borderRadius: '15px' }}>
        <Form onSubmit={handleSubmit} className="fluid text-wrap">
          <div style={{ background: 'black' }} className="text-center text-white fluid text-wrap">
            <p className="h5 mt-2 py-4 font-weight-bold">Contact Us</p>
          </div>
          <Card.Body className="text-wrap">
            <Form.Group controlId="formBasicName" className=" text-wrap">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Enter name" />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group controlId="formBasicMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" placeholder="Enter message" />
            </Form.Group>

            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Send me a copy of this message" />
            </Form.Group>

            <Button className="btn-primary btn-block my-3 mx-0 w-25" type="submit" disabled={isLoading}>
              Send
            </Button>
            {isLoading && <LoadingSpinner />}
          </Card.Body>
        </Form>
      </Card>
      <CustomModal show={showModal} handleClose={handleCloseModal} title={modalTitle} message={modalMessage} />
      
    </Container>
  );
};

export default Contact;
