import React from 'react';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import './css/style.css'
import project1 from './assets/project1.png';
import brandLogo from './assets/loopforever.gif';
const project1Jpg = <img src={project1} alt='Project 1' className='project1Style card-img-top img-fluid' />;
const project2Jpg = <img src={brandLogo} alt='Project 1' className='project1Style card-img-top img-fluid ' />;
const Projects = () => {
  return (
    <Container Fluid className='projectDiv' >
      <Card  className='text-wrap' style={{ width: '500rem', borderRadius: '15px', background:'#666666' }} >
        <Card.Body >
      <div style={{ background: '#999999' }} className="text-center text-white fluid text-wrap">
      <h2 className="text-center">Projects</h2>
      </div>
      <Row className='no-gutters wrapper ' >
        <Col xs={12} md={6} className='column column1'>
          <div className="card" style={{ background: '#ffffff' }}>
            {project1Jpg}
            <div className="card-body">
              <Accordion defaultActiveKey="0" alwaysOpen>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header flush >
                  <h5 className="card-title">Project 1</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                  <p className="card-text">
                  The Project Volunteers Philippines is a Volunteering Organization that promotes volunteerism in all ages, travel with them while helping our fellow Filipino in far flung areas of the country.
                  Website is created using nodejs framework, bootstrap 4 for css framework, and javascript for functions.
              </p>
                  </Accordion.Body>
                </Accordion.Item>


              </Accordion>
              

             
              <a href="https://young-fortress-20244.herokuapp.com" target='_blank' rel='noreferrer' className="btn btn-primary">
                View Project
              </a>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className='column column2'>
        <div className="card" style={{ background: '#ffffff' }}>
            {project2Jpg}
            <div className="card-body">
              <Accordion defaultActiveKey="0" alwaysOpen>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header flush >
                  <h5 className="card-title">Project 2</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                  <p className="card-text">
                Including my portfolio website as a project may seem a bit unconventional. However, I view this project as a significant accomplishment as it was my first experience learning React. Throughout the development process, I utilized several AWS micro-services, such as CodePipeline, CodeCommit, and CodeBuild. I implemented CodePipeline to automate and manage the code changes, set CodeCommit as the source stage in CodePipeline, and used CodeBuild to conduct Babel-Jest testing and build the production-ready static website that was uploaded as an output artifact to an Amazon S3 bucket. I also leveraged API Gateway, Amazon SES, and AWS Lambda function to manage the contact page and enable the functionality of sending emails. This project is entirely stateless, and I view it as a small-scale project for a serverless web application.
              </p>
                  </Accordion.Body>
                </Accordion.Item>


              </Accordion>
              

             
              <a href="/" target='_blank' rel='noreferrer' className="btn btn-primary">
                View Project
              </a>
            </div>
          </div>
        </Col>
      </Row>
      </Card.Body>
      </Card>
    </Container>
  );
};

export default Projects;
