import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import './css/spinner.css'
const SpinnerWithDelay = ({ variant, animation, delay }) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowSpinner(true);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    return showSpinner ? (
        <Spinner variant={variant} animation={animation} role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    ) : null;
};

const LoadingSpinner = () => {
    return (
        <>
            <SpinnerWithDelay variant="primary" animation="grow" delay={0} />
            <SpinnerWithDelay variant="secondary" animation="grow" delay={1000} />
            <SpinnerWithDelay variant="success" animation="grow" delay={2000} />
            <SpinnerWithDelay variant="danger" animation="grow" delay={3000} />
        </>
    );
};

export default LoadingSpinner;