import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <section id="about">
                <h2>About Me</h2>
                <p>I am a software developer with experience in building web and mobile applications using various technologies.</p>
                <p>I am skilled in languages such as JavaScript, Python, and Java and have experience with frameworks such as React, Angular, and Vue.</p>
                <p>I am always eager to learn new technologies and take on new challenges.</p>
                <p>Testing for changes in page s3 static deployment using codepipeline</p>
            </section>
        );
    }
}

export default About;
