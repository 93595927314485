import React, { useState, useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './css/Navbar.css';
import brandLogo from './assets/brand.gif';

const Navigationbar = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 736);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 782);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className='custom-navbar'>
            <Navbar.Brand>
                <Link to="/">
                    <img
                        alt="brand logo"
                        src={brandLogo}
                        width="200"
                        height="100"
                        className="d-inline-block align-top"
                    />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className={isMobile ? 'mobile-nav' : ''}>
                <Nav className="ml-auto text-wrap">
                    <Nav.Link as={Link} className="px-3 fs-5" to="/">
                        Home
                    </Nav.Link>
                    <Nav.Link as={Link} className="px-3 fs-5" to="/about">
                        About
                    </Nav.Link>
                    <Nav.Link as={Link} className="px-3 fs-5" to="/projects">
                        Projects
                    </Nav.Link>
                    <Nav.Link as={Link} className="px-3 fs-5" to="/contact">
                        Contact
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Navigationbar;
